import React, { useState, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import '../../superadmin/styles/pages/dashboard.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid, TextField, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import instance from '../../utils/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalContext } from '../../Contex'; // Adjust the path


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const Addadmin = ({ setAddadmins, openDialog, setOpenDialog, admin }) => {
    const { buttondisable, setButtondisable } = useGlobalContext();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();


    useEffect(() => {
        if (admin) {
            setEmail(admin.email || '');
        } else {
            setEmail('');
            setPassword('');
        }
    }, [admin]);

    const handleClose = () => {
        setOpenDialog(false);

        //empty value
        if (admin) {
            setEmail(admin.email || '');
        } else {
            setEmail('');
            setPassword('');
        }
    };

    // Email validation function
    const isValidEmail = (email) => {
        // Regex pattern for validating an email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    //add schools api call
    const handleAddadmins = async () => {
        if (!email || !password) {
            toast.error('please enter email and password');
            return;
        }
        // Check if the email is valid
        if (!isValidEmail(email)) {
            toast.error('Please enter a valid email address');
            return;
        }
        setButtondisable(true);
        try {
            const response = await instance.post('Admins/AdminSignup', {
                email,
                password,
            });
            if (response.data.success === true) {
                toast.success(response.data.message)
                handleClose();
                setAddadmins(true); // Trigger refresh after create Admins
                setButtondisable(false);
            } else {
                toast.error(response.data.message);
                setButtondisable(false);
            }
        } catch (error) {
            // Check if the error is due to a duplicate product name
            if (error?.response?.data?.error?.includes('E11000 duplicate key error')) {
                toast.error(`Email "${email}" already exists. Please choose a different email.`);
            } else {
                toast.error(error?.response?.data?.error || 'An unexpected error occurred');
            }
            console.log(error.response);
            setButtondisable(false);
        }
    }


    //update schools api call
    const handleUpdateadmins = async () => {
        if (!email) {
            toast.error('please enter email');
            return;
        }
        setButtondisable(true);
        try {
            const response = await instance.put(`Admins/updateAdmin?user_id=${admin._id}`, {
                email,
            });
            if (response.data.success === true) {
                toast.success(response.data.message)
                handleClose();
                setAddadmins(true); // Trigger refresh after update Admins
                setButtondisable(false);
            } else {
                toast.error(response.data.message);
                setButtondisable(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.error);
            console.log(error.response);
            setButtondisable(false);
        }
    }
    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                className="admin-admins"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {admin ? 'Update' : 'Add '} Admin
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <section>
                        <div className='add-admin'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter Your Email'
                                            label='Email'
                                            type='email'
                                            fullWidth
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </Grid>
                                {!admin ? (<Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter your Password'
                                            label='Password'
                                            type='password'
                                            fullWidth
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                </Grid>
                                ) : null}
                                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter the full name'
                                            label='Full name'
                                            type='text'
                                            fullWidth
                                            required />
                                    </div>
                                </Grid> */}
                                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={age}
                                                label="Gender"
                                                onChange={(e) => setAge(e.target.value)}
                                                required
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid> */}
                                
                            </Grid>
                        </div>
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="cancel-btn"
                        onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        className="add-btn" disabled={buttondisable} onClick={admin ? handleUpdateadmins : handleAddadmins}>
                        {admin ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    )
}

export default Addadmin;

